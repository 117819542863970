import React, { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    document.title = "Stuefeschnitt - Coiffeur in Münchenbuchsee";
  }, []);

  return (
    <div className="container" id="home-container">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div>
            <div className="p-2">
              <h1>Coiffeur Stuefeschnitt</h1>
              <p>
                Seit 14 Jahren arbeite ich mit grosser Leidenschaft als selbstständig Coiffeuse. Meine berufliche Reise begann 2010 im Herzen der Berner Altstadt, wo ich sieben Jahre lang mit Freude tätig war. Danach führte mich mein Weg nach Münchenbuchsee, wo ich aufgewachsen bin.
                Als Mutter von zwei Kindern arbeite ich Teilzeit, um Beruf und Familie ideal miteinander zu verbinden.
              </p>
              <h2>Ihr Wunsch ist mein Ziel</h2>
              <p>
                Ob klassischer oder trendiger Haarschnitt, eine Coloration, eine Formveränderung oder eine Hochsteckfrisur – ich stehe Ihnen mit meinem Fachwissen und viel Kreativität zur Seite. Gerne berate ich Sie typgerecht mit meinen fachlichen Kenntnissen und Ihren Wünschen. Gemeinsam finden wir den perfekten Look, der Ihrer Persönlichkeit entspricht.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <div className="p-2">
              <img className="img-fluid rounded" src="/images/geschaeft.webp" alt="Arbeitsplatz" width="588" height="441" />
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-md-6">
          <div>
            <div className="d-flex justify-content-center p-2">
              <img className="img-fluid rounded" src="/images/Portrait_Monika.webp" alt="Monika Schmutz" width="274" height="340" />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <div className="d-flex justify-content-center p-2">
              <a href="https://www.deesse.com/" target="_blank" title="Déesse Beratung" rel="noopener noreferrer"><img className="img-fluid rounded" src="/images/deesse_beratung.webp" alt="Déesse Beratung" width="525" height="440" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;